import { TComponent } from '@/store/builder-pro/types';
import { EComponents } from '../../components';
import { EComponentTypes } from '../../types';
import { FRIENDLY_COL_PICTURE } from './base';
import { FRIENDLY_DESCRIPTION } from './description';
import { TEXT_PROPS } from '../../props';

export const FRIENDLY_COL2: TComponent = {
  componentKey: EComponents.FRIENDLY_COL2,
  name: EComponents.FRIENDLY_COL2,
  type: EComponentTypes.FEATURE,
  displayName: 'Col2',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  props: {
    backgroundColor: {
      name: 'Background color',
      defaultValue: '',
    },
    align: {
      name: "Cols' Align",
      defaultValue: 'flex-start',
      items: ['flex-start', 'center', 'flex-end', 'space-between', 'space-around'],
    },
    pictureAlign: {
      name: 'Picture Align',
      defaultValue: 'center',
      items: ['flex-start', 'center', 'flex-end'],
    },
    imgWidth: {
      name: 'Image width',
      defaultValue: '',
    },
    colBorderRadius: {
      name: 'Border radius',
      defaultValue: '',
    },
    fontFamily: TEXT_PROPS.fontFamily,
    fontWeight: TEXT_PROPS.fontWeight,
    fontStyle: TEXT_PROPS.fontStyle,
    textFontSize: {
      ...TEXT_PROPS.fontSize,
      defaultValue: '2',
      items: ['1', '2', '3', '4', '5', '6', '7'],
    },

    textAlign: TEXT_PROPS.textAlign,
    titlesColor: TEXT_PROPS.color,
    titlesTextAlign: TEXT_PROPS.textAlign,
    titlesFontSize: {
      ...TEXT_PROPS.fontSize,
      defaultValue: '2',
      items: ['1', '2', '3', '4', '5', '6', '7'],
    },
    titlesFontFamily: TEXT_PROPS.fontFamily,
    titlesFontWeight: {
      ...FRIENDLY_DESCRIPTION.props!.fontWeight,
      defaultValue: '700',
    },
    titlesFontStyle: TEXT_PROPS.fontStyle,

    descriptionsColor: TEXT_PROPS.color,
    descriptionsTextAlign: TEXT_PROPS.textAlign,
    descriptionsFontSize: {
      ...TEXT_PROPS.fontSize,
      defaultValue: '2',
      items: ['1', '2', '3', '4', '5', '6', '7'],
    },
    descriptionsFontFamily: TEXT_PROPS.fontFamily,
    descriptionsFontWeight: TEXT_PROPS.fontWeight,
    descriptionsFontStyle: TEXT_PROPS.fontStyle,
  },
  children: Array.from({ length: 2 }, () => ({
    componentKey: EComponents.FRIENDLY_PICTURE_WITH_TEXT,
    name: EComponents.FRIENDLY_PICTURE_WITH_TEXT,
    type: EComponentTypes.COMPONENT,
    displayName: 'Col',
    noHover: true,
    noDynamic: true,
    noParent: true,
    staticChildren: true,
    children: [
      {
        ...FRIENDLY_COL_PICTURE,
        type: EComponentTypes.COMPONENT,
        displayName: 'Picture',
        noManualRemove: true,
        props: {
          ...FRIENDLY_COL_PICTURE.props,
          src: {
            ...FRIENDLY_COL_PICTURE.props!.src,
            defaultValue: '/static/constructor/placeholders/700x700.jpg',
          },
        },
      },
      {
        ...FRIENDLY_DESCRIPTION,
        type: EComponentTypes.COMPONENT,
        displayName: 'Title',
        noManualRemove: true,
        props: {
          ...FRIENDLY_DESCRIPTION.props,
          fontSize: {
            ...FRIENDLY_DESCRIPTION.props!.fontSize,
            defaultValue: '2',
          },
          fontWeight: {
            ...FRIENDLY_DESCRIPTION.props!.fontWeight,
            defaultValue: '700',
          },
          textAlign: {
            ...FRIENDLY_DESCRIPTION.props!.textAlign,
            defaultValue: 'center',
          },
        },
      },
      {
        ...FRIENDLY_DESCRIPTION,
        type: EComponentTypes.COMPONENT,
        displayName: 'Description',
        noManualRemove: true,
        props: {
          ...FRIENDLY_DESCRIPTION.props,
          fontSize: {
            ...FRIENDLY_DESCRIPTION.props!.fontSize,
            defaultValue: '2',
          },
          textAlign: {
            ...FRIENDLY_DESCRIPTION.props!.textAlign,
            defaultValue: 'center',
          },
        },
      },
    ],
  })),
};
